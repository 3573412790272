<template>
  <div class="forget">
    <div class="container">
      <div class="title">
        <div>{{ isChinese ? "注册" : "Register" }}</div>
        <img src="@/assets/images/close.png" alt="" class="close" @click="handleClose"/>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        :class="['demo-ruleForm', { 'en-ruleForm': !isChinese }]"
      >
        <el-form-item prop="email">
          <el-input
            v-model="ruleForm.email"
            :placeholder="isChinese ? '请输入邮箱' : 'Enter your email address'"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            :placeholder="
              isChinese
                ? '请输入新密码，必须包含数字、大小写字母6-16位'
                : 'Create a new password(6-16 characters)'
            "
            type="password"
            show-password
          />
        </el-form-item>
        <el-form-item prop="password_confirmation">
          <el-input
            v-model="ruleForm.password_confirmation"
            :placeholder="isChinese ? '确认密码' : 'Re-enter the password'"
            type="password"
            show-password
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)" :disabled="isDisabled">{{
            isChinese ? "注册" : "Register"
          }}</el-button>
        </el-form-item>
        <el-form-item prop="type" class="checkitem">
          <el-checkbox name="type" v-model="ruleForm.type">
            <a href="/conven" target="_blank" class="conven">{{
              isChinese
                ? "我同意注册及隐私条款"
                : "I agree with the terms & conditions."
            }}</a>
          </el-checkbox>
          <div class="tips" v-if="isChinese">
            <div>已有账号？</div>
            <a href="/login" class="sign">去登录</a>
          </div>
        </el-form-item>
        <div v-if="!isChinese" class="entips">
          Already have an account? <a href="/login">Sign in here</a>
        </div>
      </el-form>
    </div>
    <RegisterSuccess
      :isShowDialog="isShowDialog"
      v-if="isShowDialog"
      @closeDialog="closeDialog"
    />
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import RegisterSuccess from "./components/registerSuccess/index.vue";
import { Ajax, MessageTips } from "@/utils";
import { useRouter } from "vue-router";
export default defineComponent({
  components: { RegisterSuccess },
  setup() {
    const ruleFormRef = ref();
    const router = useRouter();
    const ruleForm = reactive({
      email: "",
      password: "",
      password_confirmation: "",
      type: false,
    });
    const isChinese = ref(
      // localStorage.getItem("language") === "en" ? false : true
      false
    );
    const isDisabled = ref(false)
    const isShowDialog = ref(false);
    const checkEmail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      const index = value.indexOf('@')
      console.log('-----index', index, value.slice(index + 1))
      if (index !== -1 && value.slice(index + 1).trim()) {
        callback();
      } else if (!value.length) {
        const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
      } else {
        const text = isChinese.value ? "邮箱格式错误" : "E-mail error!";
        callback(new Error(text));
      }
    };

    const checkPassword = (rule, value, callback) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
       if (!value.length) {
        const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
      } else {
        if (reg.test(value)) {
          callback();
        } else {
          let text = isChinese.value
            ? "请输入新密码，必须包含数字、大小写字母6-16位"
            : `The password needs to meet all the requirements below:
a total of 6-16 characters with a minimum of one uppercase letter, one lowercase letter and one number. No space and no Chinese character.`;
          callback(new Error(text));
        }
      }
    };

    const checkPassword2 = (rule, value, callback) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
       if (!value.length) {
        const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
      } else {
        if (value !== ruleForm.password) {
          const text = isChinese.value
            ? "两次密码不一致"
            : "Passwords do not match.";
          callback(new Error(text));
        } else if (!reg.test(value)) {
          let text = isChinese.value
            ? "请输入新密码，必须包含数字、大小写字母6-16位"
            : `The password needs to meet all the requirements below:
a total of 6-16 characters with a minimum of one uppercase letter, one lowercase letter and one number. No space and no Chinese character.`;
          callback(new Error(text));
        }
      }
      
    };

    const checkType = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        const text = isChinese.value ? "请勾选" : "Please check this box if you want to proceed.";
        callback(new Error(text));
      }
    };

    const rules = reactive({
      email: [
        {
          trigger: "blur",
          validator: checkEmail,
        },
        // {
        //   type: 'email',
        //   message: 'E-mail error!',
        //   trigger: ['blur', 'change'],
        // },
      ],
      password: [
        {
          trigger: "blur",
          validator: checkPassword,
        },
      ],
      password_confirmation: [
        {
          trigger: "blur",
          validator: checkPassword2,
        },
      ],
      type: [
        {
          trigger: "change",
          validator: checkType,
        },
      ],
    });

    const submitForm = (formEl) => {
      if (!formEl) return;
      let validateList = []
      formEl.validateField(['email', 'password', 'password_confirmation', 'type'], (valid) => {
        validateList.push(valid)
      });
      if (validateList.every((item) => item === '')) {
          // console.log("submit!");
          const { email, password, password_confirmation } =
            ruleFormRef.value.model;
          registerFn({ email, password, password_confirmation });
      } else {
        // console.log("error submit!");
        return false;
      }
    };

    const registerFn = async (data) => {
      isDisabled.value = true
      const res = await Ajax.POST({
        url: "/api/register",
        data,
      });
      if (res.data.code === 10000) {
        // MessageTips.success(isChinese ? '注册成功' : 'Registration successful')
        // ruleForm.email = ''
        // ruleForm.password = ''
        // ruleForm.password_confirmation = ''
        isShowDialog.value = true;
        isDisabled.value = false
      }
    };

    const closeDialog = () => {
      isShowDialog.value = false;
    };

    const handleClose = () => {
      router.back(-1);
    }
    return {
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      isChinese,
      registerFn,
      isShowDialog,
      closeDialog,
      handleClose,
      isDisabled
    };
  },
});
</script>
<style lang="scss" scoped>
.forget {
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  .container {
    width: 500px;
    min-height: 507px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -253px;
    margin-left: -250px;
    background: #ffffff;
    border-radius: 8px;
    padding: 38px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 43px;
      > div {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
    :deep(.el-form-item) {
      margin-bottom: 40px;
      .el-form-item__content {
        margin-left: 0 !important;
        .el-input__inner {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #eeeeee;
          padding: 0;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .el-form-item__error {
          // font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .el-button--primary {
          width: 100%;
          height: 60px;
          background: #e34c3e;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          border: none;
          margin-top: 45px;
        }
        .el-checkbox__input.is-checked + .el-checkbox__label,
        .el-checkbox__label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #e34c3e;
          border-color: #e34c3e;
        }
        .el-checkbox__inner {
          border-color: #9b9b9b;
        }
      }
    }
    .en-ruleForm {
      :deep(.el-form-item) {
        .el-form-item__content {
          .el-input__inner {
            font-size: 15px;
          }
        }
      }
    }
    .checkitem {
      margin-bottom: 0;
      :deep(.el-form-item__content) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: normal;
      }
      .tips, .conven {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9b9b9b;
        .sign {
          color: #e34c3e;
          text-decoration: none;
        }
      }
    }
    .entips {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 25px;
      text-align: center;
      > a {
        color: #d23400;
        text-decoration: none;
      }
    }
  }
}
</style>

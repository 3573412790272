<template>
  <el-dialog v-model="centerDialogVisible" width="730px" center :show-close="false" custom-class="register-success">
    <div class="title">{{isChinese ? '注册成功！': 'Registered Successfully！'}}</div>
    <div class="content">{{isChinese ? '激活邮件已发送至您的邮箱，请点击邮件中的链接以激活账户，请注意该邮件可能出现在垃圾邮箱里。' : `The activation email has been sent. Please click on the link to activate your account. If you didn't receive the activation email, please check your spam folder and make sure emails from tr35@mittrchina.com are not blocked.`}}</div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">{{isChinese ? '知道了' : 'OK'}}</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
  import { defineComponent, ref } from 'vue'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    components: {},
    props: {
      isShowDialog: Boolean
    },
    setup(props, {emit}) {
      const centerDialogVisible = ref(props.isShowDialog)
       const route = useRouter();
      const isChinese = ref(
        // localStorage.getItem("language") === "en" ? false : true
        false
      );

      const handleClose = () => {
        emit('closeDialog')
        route.push('/login')
      }
      return {
        centerDialogVisible,
        isChinese,
        handleClose
      }
    }
  })
</script>
<style lang="scss">
  .register-success {
    .el-dialog__body{word-break: break-word;}
    border-radius: 8px;
    padding: 36px 41px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0 !important;
    }
    .title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .content {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-top: 23px;
    }
    .el-dialog__footer {
      padding: 57px 0 0;
    }
    .dialog-footer {
      .el-button {
        width: 170px;
        height: 48px;
        background: #e34c3e;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        padding: 0;
      }
    }
  }
</style>
